import { CardType } from './../models/CardType';
import { MDObject } from './../models/MDObject';

export abstract class Utils {
    public static isHtmlElement(value: any): boolean {
        return (
            ('HTMLElement' in window && value instanceof HTMLElement) ||
      (value &&
        typeof value === 'object' &&
        value !== null &&
        value.nodeType === 1 &&
        typeof value.nodeName === 'string')
        );
    }

    public static getJsonDataAsync<T>(
        url: string,
        retryCount: number = 0,
        retryDelay: number = 0
    ): Promise<T> {
        const requestExecutor = (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(JSON.parse(xhr.response));
                }
            };
            xhr.onerror = () => {
                reject('Unexpected error occured');
            };
            xhr.send();
        };
        return Utils.createPromiseWithRetry<T>(
            requestExecutor,
            retryCount,
            retryDelay
        );

        // const promise = new Promise<T>((resolve, reject) => {
        //     const xhr = new XMLHttpRequest();
        //     xhr.open('GET', url);
        //     xhr.onload = () => {
        //         if (xhr.status === 200) {
        //             resolve(JSON.parse(xhr.response));
        //         } else {
        //             reject(JSON.parse(xhr.response));
        //         }
        //     };
        //     xhr.onerror = () => {
        //         reject("Unexpected error occured");
        //     }

        //     xhr.send();
        // })

    // return promise;
    }

    private static createPromiseWithRetry<T>(
        task: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void
    ) => void,
        retryCount: number,
        retryDelay: number = 1000
    ) {
        return new Promise<T>(function cb(resolve, reject) {
            const resolveRealMethod: (value: T | PromiseLike<T>) => void = (
                value
            ) => {
                resolve(value);
            };
            const rejectRealMethod: (reason?: any) => void = (reason?: any) => {
                if (--retryCount > 0) {
                    setTimeout(() => {
                        task(resolveRealMethod, rejectRealMethod);
                    }, retryDelay);
                } else {
                    reject(reason);
                }
            };
            task(resolveRealMethod, rejectRealMethod);
        });
    }

    public static removeSpaces(value: string): string {
        return value.replace(/ /g, '');
    }

    public static removeNonDigits(input) {
        return input.replace(/\D/g, '');
    }

    public static getCardType(value: string): CardType {
        const cardNumber: string = Utils.removeNonDigits(value);

        if (
            cardNumber.length >= 2 &&
      (cardNumber.slice(0, 2) === '34' || cardNumber.slice(0, 2) === '37')
        ) {
            return CardType.Amex;
        }

        return CardType.Unknown;
    }

    public static validateMdObject(MDObject: MDObject): void {
        if (!Object.prototype.hasOwnProperty.call(MDObject, 'SuccessUrl')) {
            throw new Error('\'SuccessUrl\' property required');
        }
        if (typeof MDObject['SuccessUrl'] != 'string') {
            throw new Error('\'SuccessUrl\' property must be a string');
        }
        if (!Object.prototype.hasOwnProperty.call(MDObject, 'FailUrl')) {
            throw new Error('\'FailUrl\' property required');
        }
        if (typeof MDObject['FailUrl'] != 'string') {
            throw new Error('\'FailUrl\' property must be a string');
        }
        if (!Object.prototype.hasOwnProperty.call(MDObject, 'TransactionId')) {
            throw new Error('\'TransactionId\' property required');
        }
        if (typeof MDObject['TransactionId'] != 'number') {
            throw new Error('\'TransactionId\' property must be a number');
        }
        if (!Object.prototype.hasOwnProperty.call(MDObject, 'ThreeDsCallbackId')) {
            throw new Error('\'ThreeDsCallbackId\' property required');
        }
        if (typeof MDObject['ThreeDsCallbackId'] != 'string') {
            throw new Error('\'ThreeDsCallbackId\' property must be a string');
        }
    }
}

export function parseMonthYear(
    value: string
): { month: number; year: number } | null {
    const numericStr = value.replace(/[^\d]/g, '');

    switch (numericStr.length) {
    case 2:
    case 3:
    case 5:
        return {
            month: parseInt(numericStr.substring(0, 1), 10),
            year: parseInt(numericStr.substring(1), 10),
        };
    case 4:
    case 6:
        return {
            month: parseInt(numericStr.substring(0, 2), 10),
            year: parseInt(numericStr.substring(2), 10),
        };
    default:
        return null;
    }
}

export function getBrowserInfo(): string  {
    return btoa(JSON.stringify({
        AcceptHeader: '*/*',
        JavaEnabled: navigator.javaEnabled(),
        JavaScriptEnabled: true,
        Language: navigator.language,
        ColorDepth: screen.colorDepth.toString(),
        Height: screen.height.toString(),
        Width: screen.width.toString(),
        TimeZone: new Date().getTimezoneOffset().toString(),
        UserAgent: navigator.userAgent,
    }));
}
