import { HexPacketHelper } from '../helpers/HexPacketHelper';
import { getBrowserInfo, parseMonthYear, Utils } from '../helpers/Utils';
import { ValidationHelper } from '../helpers/ValidationHelper';
import {
  CardData,
  CardFields,
  CardType,
  CheckoutMode,
  CheckoutSettings,
  KeyValuePair,
  MDObject,
  ObjectKeysValues,
  PrivateTransactionData,
  PublicKey,
  ValidationError
} from '../models';

export class Checkout {
  protected readonly mode: CheckoutMode = CheckoutMode.Default;
  protected readonly publicId: string;

  protected publicKey: PublicKey;

  protected validators: Partial<Record<CardFields, (...args: any[]) => ValidationError | null>>;

  protected readonly asyncCryptogramPromisesResolvers: Array<
    KeyValuePair<CardData, (value: any | PromiseLike<string>) => void>
  > = [];

  constructor(settings: CheckoutSettings) {
    if (settings == null) {
      throw new Error(
          'Use settings object to configure checkout'
      );
    }

    if (!settings.publicId) {
      throw new Error("Argument 'publicId' must be specified");
    }

    if (typeof settings.publicId !== 'string') {
      throw new Error("Argument 'publicId' must be a string");
    }

    if (settings.validators != null) {
      if (typeof settings.validators !== 'object') {
        throw new Error("Argument 'validators' must be an object");
      }
    }

    if (
        settings.key &&
        settings.key?.pem != null &&
        settings.key?.version != null
    ) {
      this.publicKey = { ...settings.key };
    } else {
      throw new Error('Argument \'public key\' required');
    }

    this.publicId = settings.publicId;

    this.mode = settings.mode ?? CheckoutMode.Default;

    this.validators = { ...ValidationHelper.validators, ...settings.validators };
  }

  public static packExternalCryptogram(type: string, cryptogram: string) {
        return btoa(
          JSON.stringify({
            Type: type,
            metaData: Checkout.getMetaData(),
            BrowserInfoBase64: getBrowserInfo(),
            Value: cryptogram
          })
        );
  }

  public static prepareMD(MDObject: MDObject): string {
    Utils.validateMdObject(MDObject);

    return btoa(encodeURIComponent(JSON.stringify(MDObject)));
  }

  /**
   * @description generates payment cryptogram in async way
   * @param fieldValues directly passed card data
   * @returns Promise which resolves with cryptogram string
   *  or rejects with object if data didn't pass validation, or rejects with string if an error occured
   */
  public createPaymentCryptogram(fieldValues?: CardData, metadata?: Record<string, unknown>): Promise<string> {
    const result = new Promise<string>((resolve, reject) => {
      const data: CardData = this.getFieldValues(fieldValues);

      if (!data.cvv) {
        data.cvv = 'none';
      }

      const validators = this.getValidators(this.mode);

      const validationMessageMap = this.validate(data, this.mode, validators);
      if (validationMessageMap) {
        reject(validationMessageMap);
        return;
      }

      if (this.publicKey != null) {
        // already got key so resolve instantly

        const packet = this.getCryptogramPacket(data, metadata);

        resolve(packet);
      } else {
        // This will be resolved when we get publicKey from api
        this.asyncCryptogramPromisesResolvers.push(
          new KeyValuePair(data, resolve)
        );
      }
    });

    return result;
  }

  /**
   * @description method which returns card data
   * @param fieldValues card values passed directly
   * @returns CardData
   */
  protected getFieldValues(fieldValues: CardData | undefined): CardData {
    return fieldValues ? {...fieldValues} : {};
  }

  /**
   * @description creates cryptogram from card data or cvv based on this.mode
   * @param data CardData
   * @returns cryptogram string
   */
  protected getCryptogramPacket(data: CardData, metaData?: Record<string, unknown>) {
    switch (this.mode) {
      case CheckoutMode.Default: {
        let month: number, year: number;
        if (typeof data.expDateMonthYear !== 'undefined') {
          const monthYearObj = parseMonthYear(data['expDateMonthYear']);
          month = monthYearObj!.month;
          year = monthYearObj!.year;
        } else {
          month = parseInt(data.expDateMonth!, 10);
          year = parseInt(data.expDateYear!, 10);
        }

        return btoa(
          JSON.stringify({
            Type: 'CloudCard',
            metaData: {
                ...metaData,
                ...Checkout.getMetaData()
            },
            BrowserInfoBase64: getBrowserInfo(),
            Format: 1, // PKCS1 padding
            CardInfo: {
              FirstSixDigits: Utils.removeNonDigits(data.cardNumber).slice(0, 6),
              LastFourDigits: Utils.removeNonDigits(data.cardNumber).slice(-4),
              ExpDateYear: HexPacketHelper.numberToEvenLengthString(year % 100),
              ExpDateMonth: HexPacketHelper.numberToEvenLengthString(month)
            },
            KeyVersion: this.publicKey.version,
            Value: HexPacketHelper.createCryptogram(
              this.publicId,
              Checkout.getPrivateTransactionData(data),
              this.publicKey
            )
          })
        );
      }
      case CheckoutMode.Cvv:
        return HexPacketHelper.createCryptogram(
          this.publicId,
          Utils.removeNonDigits(data.cvv!),
          this.publicKey
      );
    }
  }

  protected getValidators(mode: CheckoutMode, cardType?: CardType): CardFields[] {
    let validationArray: CardFields[] = [];

    switch (mode) {
      case CheckoutMode.Cvv: {
        validationArray = [CardFields.cvv];
        break;
      }
      case CheckoutMode.Default: {
        validationArray = [CardFields.cardNumber, CardFields.cvv];
        break;
      }
    }

    return validationArray;
  }

  protected validate(data: any, mode: CheckoutMode, validators: CardFields[]) {
    let isValid = true;

    const validationMessageMap: ObjectKeysValues<ValidationError | string> = {};

    validators.forEach(fieldName => {
      if (typeof data[fieldName] === 'undefined') {
        throw new Error("Field '" + fieldName + "' must be provided");
      }
    });

    if (mode != CheckoutMode.Cvv) {
      if (
        typeof data['expDateMonthYear'] === 'undefined' &&
        (typeof data['expDateMonth'] === 'undefined' ||
          typeof data['expDateYear'] === 'undefined')
      ) {
        throw new Error(
          "Field '" +
            'expDateMonthYear' +
            "' or both fields '" +
            'expDateMonth' +
            "' and '" +
            'expDateYear' +
            "'  must be provided"
        );
      }
    }

    for (const fieldName in data) {
      const validator = this.validators[fieldName];
      let error = undefined;
      if (
          validator &&
          mode == CheckoutMode.Default &&
          fieldName == CardFields.cvv
      ) {
        error = validator(data[fieldName], data[CardFields.cardNumber]);
      } else if (validator) {
        error = validator(data[fieldName]);
      }

      if (error != null) {
        isValid = false;
        validationMessageMap[fieldName] = error;
      }
    }

    return isValid ? null : validationMessageMap;
  }

  protected static getPrivateTransactionData(
    data: CardData
  ): PrivateTransactionData {
    let month: number, year: number;
    if (typeof data.expDateMonthYear !== 'undefined') {
      const monthYearObj = parseMonthYear(data['expDateMonthYear']);
      month = monthYearObj!.month;
      year = monthYearObj!.year;
    } else {
      month = parseInt(data.expDateMonth!, 10);
      year = parseInt(data.expDateYear!, 10);
    }

    return {
      Number: Utils.removeNonDigits(data.cardNumber!),
      CVV: Utils.removeNonDigits(data.cvv!),
      ExpDateMonth: month,
      ExpDateYear: year
    };
  }

  protected static getMetaData(): {PaymentUrl: string; ReferrerUrl: string} {
      return {
          PaymentUrl: (window.location != window.parent.location) ? document.referrer : document.location.href,
          ReferrerUrl: document.referrer
      };
  }
}
