export type CreateAltPayRequest = {
  PublicId: string;
  AltPayType: AltPayType;
  Scenario?: Scenario;
  Amount: number;
  Currency: string;
  Description?: string;
  Email?: string;
  AccountId?: string;
  InvoiceId?: string;
  JsonData?: string;
  IpAddress?: string;
  CultureName?: string;
  PaymentUrl?: string;
  FailRedirectUrl?: string;
  SuccessRedirectUrl?: string;
  Scheme?: Scheme;
  SaveCard?: boolean;
  PromoCode?: string;
  Version?: number;
  OrderNumber?: string;
};

export enum Scenario {
  Unknown,
  Cryptogram,
  Token,
  Widget,
  Orders,
  Custom,
  Subscriptions,
  MobileSdk,
  QrLink,
  QrImage,
  WebSdk,
  Blocks,
  Infoshop
}

export enum AltPayType {
  SbpLink= "SbpLink",
  SbpQr = "SbpQr",
  TinkoffPayLink = "TinkoffPayLink",
  TinkoffPayQr = "TinkoffPayQr",
  Installment = "TcsInstallment",
  Spei = "Spei",
  TcsBnplDolyame = "TcsBnplDolyame",
  Som = "Som",
  Loan = "Loan",
  CashOxxo = "CashOxxo",
  CashCStores = "CashCStores",
  CashPharmacy = "CashFarmacias"
}

export enum Scheme {
  Сharge,
  Auth
}

export type Split = {
  PublicId: string;
  Amount: number;
}
